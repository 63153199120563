import * as React from 'react';
import Svg, {SvgProps, Circle} from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Circle cx={20} cy={20} r={20} fill="#D7E3EA" />
  </Svg>
);

export default SvgComponent;
