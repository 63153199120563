/* eslint-disable */
import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={64}
    height={63}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.823 9.806C28.823 5.742 31 5 33.178 5c2.177 0 4.354.742 4.354 4.806h-8.71Z"
      fill="#F8CC78"
      stroke="#0E151B"
      strokeWidth={2}
    />
    <path
      d="M17.211 22.85c.407-7.318 6.46-13.044 13.79-13.044h4.354c7.33 0 13.383 5.726 13.79 13.045l.366 6.598a20.796 20.796 0 0 0 4.348 11.614l.413.53c2.344 3.015.196 7.407-3.623 7.407H15.706c-3.819 0-5.967-4.392-3.622-7.407l.412-.53a20.796 20.796 0 0 0 4.348-11.614l.367-6.598Z"
      fill="#FADCA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.009 9.806c-7.33 0-13.383 5.726-13.79 13.045l-.366 6.598a20.795 20.795 0 0 1-4.349 11.614l-.412.53C9.747 44.608 11.895 49 15.714 49h4.355c-3.819 0-5.967-4.392-3.622-7.407l.412-.53a20.797 20.797 0 0 0 4.349-11.614l.366-6.598c.407-7.319 6.46-13.045 13.79-13.045h-4.355Z"
      fill="#F8CC78"
    />
    <path
      d="M16.484 29.403v0c0 4.234-1.411 8.347-4.01 11.689l-.626.804C9.6 44.787 11.66 49 15.322 49H50.65c3.82 0 5.967-4.392 3.623-7.407l-.413-.53a20.796 20.796 0 0 1-4.348-11.614l-.367-6.598c-.406-7.319-6.46-13.045-13.789-13.045v0H31v0c-7.33 0-13.384 5.747-13.79 13.065v0"
      stroke="#0E151B"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <circle
      cx={46.698}
      cy={14.465}
      r={5.279}
      fill="#ED364C"
      stroke="#0E151B"
      strokeWidth={2}
    />
    <path
      d="M9.86 23.323 5.12 21.352M10.22 31.81l-3.748 2.332M9.23 27.508l-7.262 1.426M38.29 54.606 40.7 60M35.029 55.513l.169 5.712M57.132 12.064l5.089-.663M52.575 4.894 54.656 1M55.584 8.125l5.577-4.867"
      stroke="#0E151B"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export default SvgComponent
